@import "../../../globalVariables.less";

.CoverageDetails {
  .CoverageDetails__legalInfo {
    margin-left: 10px;
    font-weight: 100;
  }

  .cotizando,
  .quotation-error {
    text-align: center;
    font-weight: 500;
    color: @liderarVioleta;
  }
  .quotation-empty {
    text-align: center;
    font-weight: 500;
    color: @liderarVioleta;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
  }

  .coverages,
  .coverage-summary {
    max-height: 100%;
    background-color: white;
    box-shadow: @layout-shadow;
    border-radius: 4px;
    padding: 8px;
    overflow-y: auto;
  }

  .coverages {
    margin-right: 16px;

    .coverages-list {
      overflow: auto;
      height: 85%;
    }

    .title {
      color: @liderarVioleta;
      margin: 5px 10px;
      font-size: 1.3rem;
    }

    p.subtitle {
      margin: 0 10px;
      color: rgba(0, 0, 0, 0.2);
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  .coverage-summary-container {
    .coverage-summary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 10px;
      .coverage-summary__numerator_id {
        margin-left: 10px;
      }
      .ant-card-body {
        padding: 10px;
      }

      .title {
        color: black;
        font-weight: 400;
        margin: 5px 10px;
        font-size: 1.3rem;
      }

      .subtitle {
        display: inline;
        font-weight: bold;
        margin: 0 10px;
        font-size: 0.95rem;
      }

      .ant-divider-horizontal {
        margin: 5px 0;
        border-top: 2px solid #eeeeee;
      }

      .download-button {
        background-color: white;
        color: @liderarVioleta;
        border: 1px solid @liderarVioleta;
        align-self: center;
      }
      .disabled-download-button {
        background-color: white;
        border: 0.5px solid #e0e0e0;
        color: #e0e0e0;
        align-self: center;
      }

      .icon {
        font-size: 22px;
      }

      .SummaryItem {
        .label {
          text-align: right;
        }

        .value {
          font-weight: 500;
          padding-left: 10px;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}

@primary-color: #454f58;