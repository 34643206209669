.home-container {
  .HomeCard {
    .card-home {
      .card-body {
        display: flex;
        flex-direction: column;
        .ant-btn {
          align-self: center;
          position: absolute;
          bottom: 0px;
          margin-bottom: 20px !important;
          min-width: 200px !important;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .HomeCard {
      .card-home {
        .card-body {
          img {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

@primary-color: #454f58;