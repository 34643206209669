@import "../../globalVariables.less";

.modal-summary {
  .ant-collapse-content {
    .ant-row {
      .ant-col {
        .ant-form-item-explain-error {
          font-weight: 400;
        }
      }
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: black;
  }
  .ant-collapse-header-summary {
    padding-top: 20px;
    font-weight: bold;
    color: @liderarVioleta;
    font-size: 1.3rem;
    padding-left: 20px;
  }
  .bold-label {
    margin-top: 25px;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  .input {
    font-weight: bold;
    margin-top: 15px;
    color: @liderarVioleta;
  }
  .linea {
    height: 2px;
    background-color: @liderarVioleta;
  }
}

@primary-color: #454f58;