@import "../../../globalVariables.less";

div > .modal-collection {
  min-width: 40vw;
  .ant-descriptions-row > td {
    padding-bottom: 3px;
  }
  .ant-modal-header {
    border-bottom: 0px;
  }
  .ant-modal-body {
    padding: 5px 30px 5px 30px;

    .ant-row {
      .ant-col {
        border: 1px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        background-color: #fdfdfd;
      }
    }

    .ant-descriptions-item-label {
      justify-content: right;
      min-width: 150px;
    }
    .ant-descriptions-item-content {
      text-align: left;
      padding-left: 10px;
      font-weight: bold;
    }
  }
  .ant-modal-footer {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    border-top: 0px;

    .see-data-button {
      background-color: @liderarVioleta;
      font-weight: 300;
      min-width: 150px;
      max-width: 221px;
      border-radius: 5px;
      margin: 5px;
      height: 50px;
      font-size: 16px;
      &:hover {
        background-color: @liderarVioleta;
      }
    }
  }
}

@media (max-width: 500px) {
  .modal-collection {
    .ant-modal-body {
      .ant-descriptions-item-label {
        justify-content: right;
        min-width: 120px;
      }
      .ant-descriptions-item-content {
        text-align: left;
      }
    }
    .ant-modal-footer {
      flex-direction: column;
      .see-data-button {
        margin-bottom: 100px;
      }
    }
  }
}

@primary-color: #454f58;