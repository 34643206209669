@import "../../globalVariables.less";

.ellipsisColumn {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pro-table-collection {
  .collection-toolbar,
  .pagination-bar {
    padding-right: 15px;
  }

  h1 {
    padding-top: 20px;
    padding-bottom: 10px;
    color: @liderarVioleta;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .back-button {
      border-radius: 4px;
      font-weight: 300;
      min-width: 150px;
      border: 1px solid @liderarVioleta;
      margin: 5px;
    }
    .nueva-solicitud {
      background-color: @liderarVioleta;
      font-weight: 300;
      min-width: 150px;
      border-radius: 4px;
      margin: 5px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    cursor: pointer;
    background-color: @liderarVioletaHover;
  }
}

.modal-create-collection {
  .SaveFormFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}

@primary-color: #454f58;