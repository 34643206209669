@import "../../globalVariables.less";

.LoginLayoutContent {
  border-radius: 15px;
  box-shadow: @layout-shadow;
  text-align: center;
  margin: 10vh auto;
  height: 80vh;
  width: 80%;

  -webkit-box-shadow: @layout-shadow;
  -moz-box-shadow: @layout-shadow;

  .leftSide {
    background-image: url("/assets/logo-login-layout.jpg");
    background-size: cover;
    border-radius: 15px 0 0 15px;
    display: flex;
  }

  .rightSide {
    background-color: white;
    border-radius: 15px;
    padding-bottom: 20px;
  }

  .logo {
    width: 200px;
    margin-top: 20px;
  }

  .loginTitle {
    align-self: center;
    color: white;
    margin: auto;
  }

  .welcome {
    color: #646464;
    font-size: 16px;
  }

  .bold {
    font-weight: bold;
  }
}

@primary-color: #454f58;