@import ".././../../../globalVariables.less";

.AseguradoItem {
  position: relative;
  margin-bottom: 8px;

  .asegurado-icon {
    font-size: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .asegurado-row {
    margin-left: 35px;
  }

  .second-col {
    text-align: right;
    padding-right: 5px;
  }

  .third-col {
    margin-left: 10px;
  }

  .aseg-value {
    font-weight: 500;
  }

  div.ant-card-body {
    padding: 15px;
  }
  div.ant-card {
    border: 1px solid #e7d7ff;
    border-radius: 5px;
    .ant-card-body {
      height: 120px;
    }
  }
  div.ant-card:hover {
    background-color: #d6befa20;
    border-color: @liderarVioleta;
  }
}

@primary-color: #454f58;