@import "../../../globalVariables.less";

.CoverageItem {
  position: relative;
  margin-bottom: 8px;

  .coverage-icon {
    font-size: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .coverage-row {
    margin-left: 2vh;
    margin-right: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-col {
      display: flex;
      width: 50%;
    }

    .right-col {
      display: flex;
      width: 50%;
      justify-content: flex-end;
      text-align: right;
    }
    .premio-value {
      width: 55%;
      text-align: right;
      margin-right: 5px;
    }

    .price-value {
      width: 45%;
      max-width: 95px;
      text-align: left;
      margin-left: 3px;
      font-weight: 500;
    }
    .second-col {
      margin-right: 5px;
      width: 25%;
      min-width: 70px;
      text-align: right;
    }

    .aseg-value {
      font-weight: 500;
      width: 75%;
      text-align: left;
    }
  }

  div.ant-card-body {
    padding: 15px;
  }
  div.ant-card {
    border: 1px solid #e7d7ff;
    border-radius: 5px;

    min-height: 80px;
    height: auto;
  }
  div.ant-card:hover {
    background-color: #d6befa20;
    border-color: @liderarVioleta;
  }
}

@primary-color: #454f58;