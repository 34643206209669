@import "../../../../../globalVariables.less";

.PaymentsTable_ {
  div.ant-table-title {
    font-weight: bold;
    color: @liderarVioleta;
  }

  .ant-table-cell {
    text-align: center;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background: white;
  }
}

@primary-color: #454f58;