@import "../../../globalVariables.less";

.HomeCard {
  div.ant-card {
    border-radius: 5px;
    box-shadow: @layout-shadow;
    min-height: 540px;
    margin: 0 20px;
    min-width: 325px;
  }

  div.ant-form-item-label {
    padding: 0;
  }

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .card-header {
    display: flex;
    padding: 0 24px;
  }

  .card-icon {
    font-size: 2rem;
    margin-right: 25px;
  }

  .card-title {
    font-size: 1.7rem;
  }

  .card-title,
  .card-icon {
    color: @liderarVioleta;
  }

  .card-divider {
    border-top: 2px solid #bed78d;
    margin: 10px 0;
  }

  .card-body {
    margin: 0 24px;
  }
}

@primary-color: #454f58;