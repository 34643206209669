@import "../../../globalVariables.less";

.CollectionPrizeToBePaid {
  margin-top: 10px;

  .PrizeToBePaidTitle {
    margin: 0;
    font-size: 1.5rem;
    margin-left: 15px;
    font-weight: 500;
  }

  .ant-pro-table-list-toolbar
    .ant-space.ant-space-horizontal.ant-space-align-center.ant-pro-table-list-toolbar-right {
    font-size: 18px;
    font-weight: 500;

    span {
      padding-left: 7px;
      font-size: 1.25rem;
      font-weight: 500;
      color: @liderarVioleta;
    }
    .ant-btn-primary span {
      color: #e5ddf2 !important;
    }
  }

  .button-details span {
    color: @liderarVioleta;
    text-decoration: underline;
  }

  .collection-prize-to-be-paid-table
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    cursor: auto;
    background-color: @liderarVioletaHover;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .ant-card-body {
    padding: 0;
  }

  .total-amount-container,
  .pagination-bar {
    padding-right: 15px;
  }
}

@primary-color: #454f58;