@import "../../globalVariables.less";

.modal-confirm-function {
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-body {
      flex-direction: column;
      align-items: center;

      .anticon {
        display: none;
      }
      .ant-modal-confirm-title {
        font-weight: bold;
      }
      .ant-modal-confirm-content {
        margin-left: 0px;
        justify-content: center;
        text-align: center;
      }
    }
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: space-around;
      float: none;
      button {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
      .ant-btn-primary {
        color: @liderarVioleta;
        font-weight: bold;
      }
    }
  }
}

@primary-color: #454f58;