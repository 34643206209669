#CertificadoCoberturaDownloadsPage {
  .ellipsisColumn {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.certificadoCoberturaDownloadsPage {
  .formItem {
    margin-bottom: 0px;
  }
  .poliza-input .mg {
    margin-right: 8px;
  }
}

@primary-color: #454f58;