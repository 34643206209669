@import "../../../../globalVariables.less";

.PolizaItem {
  margin-bottom: 9px;
  position: relative;

  .poliza-icon span {
    font-size: 25px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .ant-row {
    margin-left: 20px;
  }

  div.ant-card-body {
    padding: 15px;
  }

  div.ant-card-bordered {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 5px;
    border-left: 1px solid #e8e8e8;
  }
  div.ant-card-bordered:hover {
    border-top: 1px solid @liderarVioleta;
    border-right: 1px solid @liderarVioleta;
    border-bottom: 1px solid @liderarVioleta;
    border-left: 1px solid @liderarVioleta;
    background-color: #d6befa20;
  }

  .vigente {
    border-left: 4px solid #52c41a;
  }

  .asegurado-row,
  .vehicle-row {
    margin-bottom: 3px;
  }

  .more-button {
    font-size: 20px;
  }

  .more-container {
    position: absolute;
    right: 5px;
    top: 15px;
  }

  .first-col {
    text-align: right;
  }
  .second-col {
    font-weight: 500;
    text-align: right;
    padding-right: 5px;
  }

  .third-col {
    font-weight: bold;
    margin-left: 10px;
  }

  .poliza-number {
    font-weight: 500;
  }

  span.ant-tag {
    font-weight: 500;
  }
}

.TagValidity {
  font-size: 1.2em;
}

@primary-color: #454f58;