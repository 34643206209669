@import "../../../../globalVariables.less";

.NoResults {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;

  h1 {
    color: @liderarVioleta;
    font-weight: bold;
  }

  p {
    font-size: 1.2em;
    color: #23232380;
  }

  img {
    width: 90px;
    height: auto;
    top: -30px;
    position: relative;
  }
}

@primary-color: #454f58;