.messageRegExp {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 3;
  justify-content: flex-start;
  .textRegExp {
    text-align: left;
    font-size: 12px;
    margin-bottom: 0;
  }
}

.ant-table-container {
  .inactive-row {
    background: rgb(206, 206, 206);
  }
}
.ant-pro-table-search {
  padding: 0;
  margin: 0;
  .ant-row.ant-row-start {
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-col:last-child {
      margin-left: auto;
      min-width: fit-content;
      margin-top: auto;

      .ant-row.ant-form-item div.ant-col.ant-form-item-label {
        max-height: 0 !important;

        height: 0 !important;
        label {
          max-height: 0 !important;
          height: 0 !important;
        }
      }
      .ant-form-item-control-input-content
        > .ant-space.ant-space-horizontal.ant-space-align-center
        > .ant-space-item {
        padding-top: 5px;
        margin-right: -5px;
        margin-left: auto;
      }
    }
  }
}

@primary-color: #454f58;