@import "../../../globalVariables.less";

.SearchForm {
  margin: 18px;

  .form-title {
    color: @liderarVioleta;
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  div .ant-form-item-label {
    padding: 0 0 4px;
  }

  .form-buttons,
  .search-value {
    button:nth-child(1) {
      margin-right: 7px !important;
    }
    button:nth-child(2) {
      margin-left: 7px !important;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .radio-value {
    flex-grow: 1;
    text-align: center;
    font-weight: 500;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid @liderarVioleta;
    background-color: #7637900d;
    color: @liderarVioleta;
  }

  input.ant-input.inputForm {
    color: @liderarVioleta;
    font-weight: 500;
  }

  form.poliza-input {
    margin-bottom: 20px;
  }

  form.poliza-input .ant-row {
    justify-content: space-around;
  }

  .poliza-input .mg {
    margin-right: 8px;
  }
}

@primary-color: #454f58;