.BusquedaPolizasLayout {
  margin-top: 15px;

  .search-side {
    background-color: white;
    height: 300px;
    margin-right: 15px;
  }

  .results-side {
    background-color: white;
    min-height: 80vh;
  }

  div.results-side {
    padding: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 1200px) {
  .BusquedaPolizasLayout {
    .search-side {
      margin-bottom: 20px;
    }
  }
}

@primary-color: #454f58;