@import url("../../../globalVariables.less");

.renovationFormPage {

  .read-only.ant-picker.ant-picker-disabled,
  .read-only.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .read-only.ant-input-disabled,
  .read-only.ant-input-number-disabled {
    background-color: white;
    color: black;

    .ant-picker-input>input[disabled] {
      color: black;
    }
  }

  .read-only-disabled {
    background-color: #f5f5f5;
  }

  .collapse {
    max-height: 100%;
    margin-bottom: 10px;

    button.button-upload {
      border: 1px solid @liderarVioleta;
      border-radius: 10px;
    }

    .ant-upload-list-item {
      border: 1px solid @liderarVioleta;
      border-radius: 10px;
    }

    .texto-upload {
      padding-top: 5px;
      font-weight: 400;
    }
  }

  input {
    font-weight: 100;
  }

  .ant-collapse-content {
    .ant-row {
      .ant-col {
        .ant-form-item-explain-error {
          font-weight: 400;
        }
      }
    }
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .label-space {
    label {
      display: flex;
    }
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: black;
  }

  .ant-collapse {
    font-weight: bold;
    overflow-x: hidden;

    .ant-collapse-item {
      .ant-collapse-header {
        color: @liderarVioleta;
        font-size: 1.3rem;
      }
    }
  }
}

.AutoClosableModal {
  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .ant-modal-confirm-content {
        margin-left: 0;
      }

      .anticon {
        display: none;
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: @liderarVioleta;
        border-radius: 5px;
      }
    }
  }
}

.information-form-patente-extranjera .ant-collapse-header {
  background-color: rgb(128, 51, 142, 0.85);
}

.information-form-patente-extranjera .ant-collapse-header {
  color: white !important;
}
@primary-color: #454f58;