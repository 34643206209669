@import "../../../../globalVariables.less";

.TopBar {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;

  div.ant-card-body {
    border: 1px solid #e7d7ff;
    background-color: #e7d7ff;
    border-radius: 3px;
    padding: 5px;
  }

  .insured-name {
    font-weight: 500;
  }

  p {
    margin: 5px 0;
    margin-left: 10px;
  }

  .results {
    text-align: end;
    padding-right: 30px;
    font-weight: 500;
  }

  .ant-input-affix-wrapper {
    align-self: flex-end;
    margin-bottom: 5px;
    border-radius: 8px;
  }

  .anticon {
    color: black;
  }

  .top-bar-title {
    color: @liderarVioleta;
  }
  .top-bar-header {
    display: inline-flex;
    justify-content: space-between;
  }

  .top-bar-radio-group {
    align-self: flex-end;
    margin-bottom: 5px;
  }
}

@primary-color: #454f58;