.mainMenuContainer {
  position: relative;
  .maintenance-tag {
    position: absolute;
  }
  .env-tag {
    position: absolute;
    right: 0;
  }
  .logo {
    width: 150px;
    margin-top: 0;
  }
}

@primary-color: #454f58;