@import "~antd/lib/style/themes/default.less";

.RegisteredLayout {
  background-size: cover;
  height: 100%;
  overflow-y: auto;

  .site-layout-content {
    width: 85%;
    margin: auto;
    margin-top: 25px;
  }

  > aside.ant-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    color: white;
    white-space: nowrap;
    z-index: 2;
  }
  > section.ant-layout {
    color: inherit;
    height: 100vh;
    overflow-y: auto;
    > header.ant-layout-header {
      background-color: white;
      padding-left: 24px;
    }
    > .ant-layout-content {
      overflow: initial;
      margin: 24px;
    }
    transition: all 0.2s;
  }
}

.ant-layout-header {
  background: #ffffff;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: #80338e;
}

.ant-menu-submenu-title > span {
  color: #80338e;
}

.anticon {
  color: #80338e;
}
.ant-btn-primary .anticon {
  color: #ffffff;
}

.footer {
  text-align: center;
}

@primary-color: #454f58;