@import "../../globalVariables.less";

.modal-configure-multiple {
  top: 20px;
  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
    padding: 24px;
    padding-top: 10px;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @liderarVioleta;
      border-color: @liderarVioleta;
    }
  }
  .containerChecks {
    display: flex;
    flex-direction: column;
    label {
      margin-left: 0 !important;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      background-color: white;
      font-weight: 400;
      min-width: 150px;
      max-width: 221px;
      padding: 5px;
      height: 50px;
      border-radius: 5px;
    }
    .ant-btn-primary {
      background-color: @liderarVioleta;
      font-weight: 400;
      min-width: 150px;
      max-width: 221px;
      padding: 5px;
      height: 50px;
      border-radius: 5px;
    }
  }
}

@primary-color: #454f58;