.EndosoSelect {
  display: flex;
  flex-direction: row;

  p {
    margin-right: 10px;
    display: inline-block;
  }

  p.title {
    margin-bottom: 0;
    padding: 2px 0;
    font-size: large;
  }
}

@primary-color: #454f58;