@import "~antd/es/style/themes/default.less";

.SaveForm {
  .ant-modal-body {
    padding: 24px;
    padding-top: 10px;
  }
  form {
    .ant-input-number,
    .ant-picker {
      width: 100% !important;
    }
    .ant-form-item-explain {
      margin-top: 2px;
    }
  }
  .ant-btn-primary {
    .anticon {
      color: white;
    }
  }
}

.SaveFormFooter {
  .Reset,
  .Cancel {
    margin-right: 10px;
  }
  .ant-btn {
    height: 34px;
    font-size: 14px;
    .anticon {
      font-size: 18px;
    }
  }
}

@primary-color: #454f58;