@import "~antd/dist/antd.less";
@import "globalVariables.less";

html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

// 兼容IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

/* Css agregado */
.content-search-table {
  width: 100%;
  padding-right: 100px;
}

.ant-table-tbody .custom-table-colum-date {
  text-align: center !important;
}

.hideInSearch .ant-pro-table-search {
  display: none !important;
}

.renderFormItem {
  height: 30px;
}
.renderFormItem.TextArea {
  height: auto !important;
  .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
}

.renderFormItemInfo {
  margin-bottom: -20px !important;
  padding: 5px;
  font-style: italic;
}

.TextInfo {
  font-style: italic;
}

.ant-message {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding-top: 200px !important;
  background: #00000080 !important;
  pointer-events: all;
}

.ant-pro-sider-logo h1 {
  font-family: "Bebas Neue", Avenir, "Helvetica Neue", Arial, Helvetica,
    sans-serif;
  letter-spacing: 3.5pt;
}

.site-page-header-responsive {
  height: 300px;
  margin: -24px !important;
  padding: 24px !important;
  background-color: @liderarVioletaHover !important;
}

.ant-pro-table {
  .ant-card-body {
    padding: 10px;
  }
  .ant-pro-table-search {
    margin-bottom: 10px;
    padding: 18px 24px;
    padding-bottom: 0;
    background: #fff;
  }
  .ant-table-content {
    .ant-table-tbody {
      tr {
        td.ant-table-cell:last-child {
          .anticon {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.ant-pro-table-search .ant-form {
  min-height: 40px;
}

.ant-input-number,
.ant-picker {
  width: 100% !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: @liderarVioletaHover !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout.bigRenderMode {
  overflow: inherit;
}

.ant-pro-global-footer {
  margin: 0 !important;
}

.ResumeText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-modal-confirm-body {
  display: flex;
  .anticon {
    margin-top: 11px;
  }
}

.ant-modal.ImportHelperModal {
  top: 10vh;
  min-width: fit-content;
  .ant-modal-content {
    max-height: 80vh;
  }
  .ant-modal-body {
    height: auto;
  }
  .ImportHelperSubtitle {
    margin-bottom: 24px;
    font-size: 14px;
    font-style: italic;
  }
  .ImportHelperModalImage {
    width: 100%;
    height: 50vh;
    object-fit: contain;
    text-align: center;
  }
}

.Link,
.Link .ant-btn-link {
  padding: 0;
  border: none;
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm {
  min-width: 500px;
}
.ant-modal-confirm-content {
  font-size: 14px;
}

.ant-pro-table.FullRowSearchOption {
  .ant-col.ant-pro-table-search-option
    > .ant-row.ant-form-item
    > .ant-col.ant-form-item-control {
    max-width: 100%;
  }
}

.ant-pro-form-query-filter .ant-form-item-control {
  .ant-space {
    display: flex;
    flex-wrap: wrap;
    .ant-btn {
      border-radius: 5px;
      font-weight: 300;
      width: 130px;
      border: 1px solid @liderarVioleta !important;
      margin: 5px;
      height: 50px;
    }

    .ant-btn-primary {
      background-color: @liderarVioleta !important;
      font-weight: 300;
      width: 130px;
      border-radius: 5px;
      margin: 5px;
      height: 50px;
    }
  }
}
.ant-card .ant-pro-table-list-toolbar {
  .ant-space {
    display: flex;
    flex-wrap: wrap;
    .ant-btn.ant-dropdown-trigger {
      border-radius: 5px;
      font-weight: 100 !important;
      width: 130px;
      border: 1px solid @liderarVioleta !important;
      color: @liderarVioleta !important;
      margin: 2px;
      height: 35px;
    }
    .span {
      font-size: 14px !important;
    }

    .ant-btn-primary {
      background-color: @liderarVioleta !important;
      font-weight: 300;
      color: white;
      width: 130px;
      border-radius: 5px;
      margin: 5px;
      height: 40px;
      font-size: 14px;
    }
  }
}

.Link.Expanded {
  .anticon {
    font-size: 16px;
    svg {
      border-radius: 5px;
    }
  }
}

.RenderFormItemContentPDF {
  .ContentPDF {
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    border: 1px solid #d9d9d9;
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-form-item-control {
    max-width: 100%;
  }
}

.ant-collapse > .collapse-panel-collection > .ant-collapse-header {
  font-weight: bold;
  color: @liderarVioleta;
}

.ant-collapse-content-active,
.ant-collapse-borderless {
  background-color: #fff;
}

.ant-popover-inner
  .ant-popover-title
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: @liderarVioleta;
}

.ant-tree-list-holder .ant-tree-treenode .ant-tree-checkbox-inner {
  background-color: @liderarVioleta;
}

/* Css para las sub pantallas que ocupan toda la sección */
.FullSectionModal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}

.pointer {
  cursor: pointer;
}

.ant-input-number {
  border-radius: 5px !important;
}

.ant-input-number:-webkit-autofill,
.ant-input:-webkit-autofill {
  background-clip: text;
  -webkit-background-clip: text;
}

input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important;
}

.ant-pro-table-search .ant-row.ant-form-item {
  flex-direction: column;
  align-items: flex-start;
  > .ant-col.ant-form-item-label {
    flex: 0 0 80px;
    justify-content: left;
    max-height: 30px;
    > label {
      font-size: 12px;
      font-weight: bold;
    }
  }
  > .ant-col.ant-form-item-control {
    width: 100%;
    justify-content: left;
    max-width: 100% !important;
  }
}
.ant-form-item-explain-error {
  text-align: left;
}

.ant-alert-info {
  background-color: #ccc !important;
  border: 1px solid #ccc !important;
}

/* inputs y colores generales */

input {
  height: 40px !important;
  border-radius: 5px !important;
  &:focus {
    border-color: @liderarVioleta !important;
  }
}

.ant-form-item-control-input-content {
  .ant-select-single .ant-select-selector {
    height: 40px !important;
    &.ant-select-selection-search {
      top: 4px !important;
    }
  }
}

.ant-col.ant-form-item-control {
  .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-select-selection-placeholder {
    margin: auto;
    font-weight: 100 !important;
  }
  .ant-select-selection-item {
    margin: auto;
  }
}

textarea {
  font-weight: 100;
}

.ant-picker {
  height: 40px !important;
  border-radius: 5px !important;
}

.ant-input,
.ant-picker,
.ant-select-selector {
  box-shadow: none !important;
  &:hover,
  &:focus,
  &:active {
    border-color: @liderarVioleta !important;
  }
}

.ant-input,
.ant-picker {
  padding: 4px 11px !important;
  height: 40px !important;
  &:hover {
    border-color: @liderarVioleta !important;
  }
}

.ant-input {
  height: 40px !important;
}

.ant-input-affix-wrapper {
  border-radius: 5px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: @liderarVioleta !important;
  box-shadow: none !important;
}

.ant-pro-table-column-setting-list {
  .ant-tree {
    input {
      height: 0px !important;
    }
  }
}

.ant-switch-checked {
  background-color: @liderarVioleta !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @liderarVioleta !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: @liderarVioleta !important;
}

.ant-picker-ok {
  button {
    background-color: @liderarVioleta !important;
  }
  .ant-btn-primary[disabled] {
    background-color: @liderarVioletaHover !important;
  }
}

.anticon.disabled-icon {
  color: #aaaaaa;
}

/* Columnas numericas */
.ant-table-content table tbody tr td.ant-table-cell.numeric-column {
  text-align: right !important;
}
/* Fin css agregado */

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
}

.non-wrap-cell {
  margin: 0;
  white-space: nowrap;
}

@primary-color: #454f58;