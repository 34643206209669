.HomeCard {
  .SearchForm {
    .form-get-poliza {
      .form-buttons {
        justify-content: center;
        .ant-btn {
          min-width: 200px !important;
        }
      }
    }
  }
}

@primary-color: #454f58;