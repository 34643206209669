@import "../../../../globalVariables.less";

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.download-modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 30px;
  font-size: 1.2em;
}

.PolizaDetails__modal {
  min-width: 70vw;
  .ant-modal-body {
    padding: 0px;
  }

  .vehicle-panel div.ant-collapse-content-box {
    padding: 0;
    .site-collapse-custom-collapse {
      width: 100%;
    }
  }

  .collapse {
    overflow: auto;
    max-height: 71vh;
  }
  .ant-modal-header {
    padding: 16px 24px 4px;
  }
  .modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    padding-right: 9%;

    p {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    color: @liderarVioleta;
  }

  .ant-collapse .ant-collapse-item .ant-carousel {
    width: 100%;
    .ant-image-img {
      object-fit: contain;
    }
  }

  .ant-collapse-header > span {
    color: black;
  }

  .detail-property {
    text-align: right;
  }

  .detail-value {
    font-weight: bold;
    margin-left: 15px;
  }

  .ant-collapse-content > .ant-collapse-content-box,
  .PolizaDetailsItem {
    display: flex;
  }

  .deuda-exigible {
    margin-top: 20px;
  }

  .saldos {
    font-style: italic;
    font-weight: 500;
  }

  .PaymentsTable {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .payments {
    color: #763790;
    font-weight: bold;
    padding-top: 9px;
  }
}

.Download__modal {
  .download-input-email {
    margin-top: 10px;
  }
}

.ModalButtons {
  .button {
    background-color: @liderarVioleta;
    border-color: @liderarVioleta;
    border-radius: 8px;
    .anticon {
      color: white;
    }
  }
}

.not_image {
  margin: 15px 40px 5px;
}

@primary-color: #454f58;