@import "~antd/lib/style/themes/default.less";

header.HeaderComponent {
  display: flex;

  > div.Filler {
    flex: 2;
  }
  > div.AvatarComponent {
    .Avatar {
      transform: translateY(-2px);
    }
    .AvatarName {
      padding-left: 6px;
      color: @text-color;
    }
  }
}

@primary-color: #454f58;