@import "../../globalVariables.less";

.quotation-table {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    cursor: pointer;
    background-color: @liderarVioletaHover;
  }

  .renderFormItem {
    height: 50px !important;
  }

  .ant-pro-table-search {
    padding: 0;
    margin: 0;
    .ant-row.ant-row-start {
      .ant-form-item {
        margin-bottom: 5px;
      }
      .ant-col:last-child {
        margin-left: auto;
        min-width: fit-content;
        margin-top: auto;
        .ant-row.ant-form-item div.ant-col.ant-form-item-label {
          max-height: 0 !important;
          height: 0 !important;
          label {
            max-height: 0 !important;
            height: 0 !important;
          }
        }
        .ant-form-item-control-input-content
          > .ant-space.ant-space-horizontal.ant-space-align-center
          > .ant-space-item {
          padding-top: 5px;
          margin-right: -5px;
          margin-left: auto;
        }
      }
    }
  }
}

@primary-color: #454f58;