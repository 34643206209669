@import "../../globalVariables.less";

.invalid-token-msg {
  display: block;
  margin: 50px 0;
  color: black;
  font-size: 1.1rem;
}

.invalid-token-button {
  background-color: @liderarVioleta;
  border-color: @liderarVioleta;
  border-radius: 8px;
  font-size: 1.1rem;
  padding-bottom: 35px;
}

.SetInitialPassword {
  background-color: white;
  padding: 1vh 1vw;
  max-width: 64% !important;
  margin: auto !important;
  height: 100%;
  border-radius: 5px;
  .ant-form-item-label {
    font-weight: 500;
  }

  .top-text {
    color: @liderarVioleta;
    font-size: 22px;
    font-weight: 700;
    margin-top: 30px;
    text-align: left;
  }

  .ingresa-text {
    color: @liderarVioleta;
    font-size: 22px;
    font-weight: 600;
    margin: 25px 0;
    text-align: left;
  }

  .ant-form-item-explain {
    margin: 5px initial !important;
  }

  .form-button {
    width: 200px !important;
    margin-top: 20px;
    background-color: @liderarVioleta;
    border-color: @liderarVioleta;
    border-radius: 8px;
    font-size: 17px;
    height: 40px;
  }

  .messageRegExp {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .textRegExp {
      text-align: left;
      font-size: 12px;
    }
  }
}

@primary-color: #454f58;