@import "../../globalVariables.less";

.loading {
  margin-bottom: 50%;
}
.ResetPassword {
  max-width: 64% !important;
  margin: auto !important;
  height: 100%;

  .ant-result-subtitle {
    color: black;
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  .ant-result-title {
    color: @liderarVioleta;
    font-weight: 700;
  }

  .top-text {
    color: @liderarVioleta;
    font-size: 22px;
    font-weight: 700;
    margin-top: 50px;
    text-align: left;
  }

  .form-link {
    float: center !important;
    color: @liderarVioleta;
    text-decoration: underline;
  }

  .form-button {
    width: 100% !important;
    background-color: @liderarVioleta;
    border-color: @liderarVioleta;
    border-radius: 8px;
    font-size: 17px;
    height: 40px;
    color: white;
  }
}

@primary-color: #454f58;