@import "../../globalVariables.less";

.Login {
  .login-form {
    max-width: 64% !important;
    margin: auto !important;
    height: 100%;

    .ant-form-item-label {
      font-weight: 500;
    }

    input#normal_login_password {
      padding: 0 !important;
      height: 30px !important;
    }

    .top-text {
      color: @liderarVioleta;
      font-size: 22px;
      font-weight: 700;
      margin-top: 50px;
      text-align: left;
    }

    .login-form-forgot {
      float: center !important;
      color: @liderarVioleta;
      text-decoration: underline;
    }

    .form-button {
      width: 100% !important;
      background-color: @liderarVioleta;
      border-color: @liderarVioleta;
      border-radius: 8px;
      font-size: 17px;
      height: 40px;
    }
  }
}

@primary-color: #454f58;