@import "../../globalVariables.less";

.Quotation {
  max-height: 80vh;
  min-height: 100vh;

  position: relative;
  .tagAmountOutOfRange {
    margin-left: 10px;
  }
  .producer_search_input {
    margin: 10px 0;
    width: 300px;

    p {
      margin: 0;
      font-weight: 500;
    }
  }

  .risk_zone {
    margin: 10px 20px 0;
  }

  .ant-form-item-has-success {
    .ant-select .ant-select-selector {
      border: 1px solid purple;
    }

    .ant-form-item-control-input-content .ant-input {
      border: 1px solid purple;
    }
    .input-with-dropdown
      .ant-select-selector
      .ant-select-selection-search
      .ant-select-selection-search-input {
      border: 1px solid purple;
    }
  }

  .quotation-container {
    background-color: white;

    .granizo .anticon {
      color: white;
    }
    .ant-switch {
      background-color: grey;
    }
    .ant-switch-checked {
      background-color: @liderarVioleta;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-collapse-item .ant-collapse-header {
      color: @liderarVioleta;
      padding-top: 0;
      padding-bottom: 7px;
    }

    .ant-collapse-item-active .ant-collapse-header {
      padding-bottom: 0px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0;
    }

    .customer-row,
    .vehicle-row,
    .second-vehicle-row {
      display: flex;
    }

    .accessories-item {
      .total-amount {
        font-size: 1.3rem;
        margin: 0;
        color: @liderarVioleta;
        font-weight: 500;
      }
    }

    .customer-container {
      padding: 5px 18px 0 18px;
    }

    .customer-vehicle-info {
      padding: 0 18px;
    }
  }
  .total-accesories {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div .ant-form-item-label {
      padding: 0;
    }
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  div .ant-form-item-label {
    padding: 0 0 3px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: black;
  }

  .form-subtitle,
  .ant-collapse-header {
    color: @liderarVioleta;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .ant-divider-horizontal {
    margin: 5px 0;
    border-top: 2px solid #eeeeee;
  }
}

@primary-color: #454f58;