.modal-related-insurance-policy {
  min-width: 75%;
  .border-table {
    border: 2px solid #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .ant-table-thead > tr > th {
    background-color: white;
    &::before {
      display: none;
    }
  }
  .ant-table-tbody .ant-table-row .ant-table-cell {
    border-bottom: none !important;
  }
}

@media (min-width: 1100px) {
  .modal-related-insurance-policy {
    min-width: 60%;
  }
}
@media (min-width: 1400px) {
  .modal-related-insurance-policy {
    min-width: 50%;
  }
}

@primary-color: #454f58;