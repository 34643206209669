.home-container {
  margin-top: 15px;
  .ant-row {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .home-container {
    .ant-row {
      .ant-col {
        margin-bottom: 30px;
        div.card-home {
          min-height: 350px !important;
        }
      }
    }
  }
}

@primary-color: #454f58;