@import "../../globalVariables.less";

.emission {
  .collapse {
    max-height: 100%;
    margin-bottom: 10px;
    button.button-upload {
      border: 1px solid @liderarVioleta;
      border-radius: 10px;
    }
    .ant-upload-list-item {
      border: 1px solid @liderarVioleta;
      border-radius: 10px;
    }
    .texto-upload {
      padding-top: 5px;
      font-weight: 400;
    }
  }
  input {
    font-weight: 100;
  }
  .cards {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    .ant-card {
      width: 50%;
      .ant-card-body {
        display: flex;
        padding: 15px 24px;
        p {
          margin-bottom: 5px;
        }
      }
    }
    .ant-card-1 {
      margin-right: 20px;
    }
  }
  .ant-collapse-content {
    .ant-row {
      .ant-col {
        .ant-form-item-explain-error {
          font-weight: 400;
        }
      }
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: black;
  }
  .ant-collapse {
    font-weight: bold;
    overflow-x: hidden;

    .ant-collapse-item {
      .ant-collapse-header {
        color: @liderarVioleta;
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 1303px) {
  .emission {
    .cards {
      flex-direction: column;
      .ant-card {
        width: 100% !important;
        margin-bottom: 15px !important;
      }
      .ant-card-1 {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 650px) {
  .emission {
    .cards {
      flex-direction: column;
      .ant-card {
        .ant-card-body {
          flex-direction: column;
          align-items: flex-start;

          .ant-col {
            max-width: 100%;
            align-items: flex-start !important;
            .ant-row {
              align-items: flex-start !important;
            }
          }
        }
      }
    }
  }
}

@primary-color: #454f58;